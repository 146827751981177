<template>
    <div>
        <v-dialog 
            v-if="dataSource" 
            v-model="isVisible" 
            transition="dialog-top-transition" 
            scrollable 
            persistent 
            max-width="700"            
        >
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>
                            <v-form ref="form" lazy-validation>
                                <template v-if="stages.length > 1">
                                    <v-expansion-panels v-model="stageModel">
                                        <v-expansion-panel
                                            v-for="(stage) in stages"
                                            :key="stage"                                            
                                        >
                                            <v-expansion-panel-header>{{ localized(dataSource.stages[stage].title) }}</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <template v-for="(field, fieldKey) in getVisibleFields(stage)">     
                                                    <component 
                                                        :is="getComponentName(field)" 
                                                        :key=skey+stage+fieldKey
                                                        :label="getText(field.title)" 
                                                        :value="field.value" 
                                                        @input="e => updateDataSource({ property: `fields.${fieldKey}.value`, value: e })" 
                                                        :rules="field.type != 'bool' ? getRules(field) : null"
                                                        :items="field.type == 'select' ? getItems(field) : null"                                                            
                                                    />                                       
                                                </template>                                                
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </template>
                                <template v-else>

                                </template>
                            </v-form>
                        </v-card-text>
                    </v-card>  
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="cyan"
                        text
                        depressed
                        @click="ok"
                        v-if="isValid"
                    >
                        {{ $t("Ок") }} 
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отменить")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import sys from '@/services/system';

export default {
    name: "formGenerator",
    oldVal: null,
    data () {
        return {
            stageModel: "stage2"
        }
    },
    filters: {
        formattedDate: function (value) {
            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        }
    },
    computed: {
        ...mapGetters('dialogs/formGenerator', ['isPending', 'isVisible', 'isValid', 'dataSource', 'skey']),
        title() {            
            return this.localized(this.dataSource.title);
        },
        stages() {
            return Object.keys(this.dataSource.stages);
        }
    },    
    methods: {
        ...mapActions('dialogs/formGenerator', ['ok', 'cancel', 'updateDataSource']),   
        testRule(v) {
            
            let result = (!['string', 'number'].includes(typeof v) && !!v) || (typeof v == 'number' && v >= 0) || (typeof v == 'string' && !!v.trim()) || this.localized({'kk': 'Поле обязательно kk', 'ru': 'Поле обязательно ru'})
            console.log('testRule', typeof v, v, result);
            return result;
        },
        validateForm() {
            this.$refs?.form?.validate();    
        },
        localized(val) {
            if (typeof val === 'string') 
                return val;            
            else if (val[this.$i18n.locale] !== undefined)            
                return val[this.$i18n.locale];

            return null;
        },
        eval(strCode) {
            console.log('val', strCode);
            return eval(strCode);
        },
        getVisibleFields(stage) {
            let r =  Object.keys(this.dataSource.fields)
                .filter(key => this.dataSource.stages[stage].fields.includes(key) && this.dataSource.fields[key].visible)
                .reduce((obj, key) => {
                    obj[key] = this.dataSource.fields[key];
                    return obj;
                }, {});

            return r;
        },
        getComponentName(field) {
            switch(field.type) {
                case "bool": return "v-field-edit-bool";
                case "string": return "v-field-edit-text";
                case "date": return "v-field-edit-date";
                case "number": return "v-field-edit-number";
                case "select": return "v-field-edit-select";
                case "label": return "v-label"
            }
        },
        getText(val){
            return Object.prototype.hasOwnProperty.call(val, "kk") && Object.prototype.hasOwnProperty.call(val, "ru") 
            ? this.localized(val)
            : typeof val === 'string' && val.startsWith('this') 
                ? eval(val) : this.$t(val);
        },
        getRules(field) {         
            let rules = !field.rules 
                ? [] 
                : field.rules.reduce((rules, key) => { rules.push(eval(this.dataSource.rules[key])); return rules }, []);
            return rules;
        },
        getItems(field) {
            if (field.alias && this.dataSource.referenses[field.alias])
            {
                let items = Object.keys(this.dataSource.referenses[field.alias]).reduce((acc, key) => {
                    acc.push({id: key, value: this.localized(this.dataSource.referenses[field.alias][key])});
                    return acc;
                }, []);

                return items;
            }
            
            return null;
        }
    },
    mounted() {
        let whenKeys = Object.keys(this.dataSource.fields).filter(key => Object.prototype.hasOwnProperty.call(this.dataSource.fields[key], 'when'));
        whenKeys.forEach(key => {                    
            let value = this.dataSource.fields[key].value === null ? 'null' : this.dataSource.fields[key].value;  
            if (Object.prototype.hasOwnProperty.call(this.dataSource.fields[key].when, value))    
            {
                let fieldsKeys = Object.keys(this.dataSource.fields[key].when[value]);
                fieldsKeys.forEach(k => {
                    let changingFields = Object.keys(this.dataSource.fields[key].when[value][k]);
                    changingFields.forEach(c => {
                        let paramValue = this.dataSource.fields[key].when[value][k][c];
                        this.$nextTick(function() {
                            this.updateDataSource({ property: `fields.${k}.${c}`, value: paramValue });
                        });
                    });
                });
            }
        });
    },
    updated() {
        this.$nextTick(function () {
            // if (this.isVisible)
            //     this.validateForm();
            //console.log('updated');
        });    
    },

    watch:{
        dataSource: {
            handler(oldVal, newVal) {
                let whenKeys = Object.keys(this.dataSource.fields).filter(key => Object.prototype.hasOwnProperty.call(this.dataSource.fields[key], 'when'));
                whenKeys.forEach(key => {                    
                    let value = this.dataSource.fields[key].value === null ? 'null' : this.dataSource.fields[key].value;  
                    if (Object.prototype.hasOwnProperty.call(this.dataSource.fields[key].when, value))                          
                    if (!this.$options.oldVal || this.$options.oldVal.fields[key].value != value)
                    {
                        let fieldsKeys = Object.keys(this.dataSource.fields[key].when[value]);
                        fieldsKeys.forEach(k => {
                            let changingFields = Object.keys(this.dataSource.fields[key].when[value][k]);
                            changingFields.forEach(c => {
                                let paramValue = this.dataSource.fields[key].when[value][k][c];
                                this.$nextTick(function() {
                                    this.updateDataSource({ property: `fields.${k}.${c}`, value: paramValue });
                                });
                            });
                        });
                    }
                });
                
                this.$options.oldVal = JSON.parse(JSON.stringify(newVal));
            },
            deep: true
        }
    }
}
</script>